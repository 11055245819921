import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  BackendConnection,
  BackendConnectionState,
  TEST_PLAYER,
} from './backend'
import classNames from 'classnames'
import s from './GameView.module.css'

import rsrc_427852_a from './images/427852_a.png'
import rsrc_427852_b from './images/427852_b.png'

import sfx_src from "./sfx/audio_sprite_master.mp3"
import useSound from 'use-sound'
import { PlayFunction } from 'use-sound/dist/types'

const sfxSpriteMap: { [key: string]: [number, number] } = {
  countdown_start: [0, 999],
  countdown_1: [1000, 999],
  countdown_2: [2000, 999],
  countdown_3: [3000, 999],
  countdown_4: [4000, 999],
  countdown_5: [5000, 999],
}

const RSRC: { [key: string]: [string, string] } = {
  "427852": [rsrc_427852_a, rsrc_427852_b]
}

const loadImage = (imgSrc: string) => {
  return new Promise<{src: string, height: number, width: number}>((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({src: imgSrc, height: img.naturalHeight, width: img.naturalWidth})
    }
    img.onerror = (ev) => {
      reject(ev)
    }
    img.src = imgSrc
  })
}

const CountdownTimer: React.FC<{to: number, play: PlayFunction}> = ({to, play}) => {
  const [secsLeft, setSecsLeft] = useState<number>(to - new Date().getTime())

  useEffect(() => {
    let t: number
    let lastSecs: number
    const refresh = () => {
      const newS = to - new Date().getTime()
      setSecsLeft(newS)
      const newSInt = Math.floor(newS / 1000)
      if ((lastSecs !== undefined) && (Math.floor(lastSecs / 1000) > newSInt)) {
        console.log(Math.floor(lastSecs / 1000), newSInt)
        if (4 >= newSInt && newSInt >= 0) {
          play({id: `countdown_${newSInt + 1}`})
        }
      }
      lastSecs = newS
      t = window.requestAnimationFrame(refresh)
    }
    refresh()
    return () => {
      if (t) window.cancelAnimationFrame(t)
    }
  }, [to, play])

  return <div>
    Countdown! {secsLeft}
  </div>
}

type GameStage = "PRE" | "COUNTDOWN" | "STARTED" | "FINISHED"

interface InternalState {
  gameStage: GameStage
  opponentScore: number
  myScore: number
  gameStartTimestamp?: number
  layout: "horiz" | "vert"
  hitPointCircles: string[]

  imgHeight?: number
  imgWidth?: number
}

const INITIAL_STATE: InternalState = {
  gameStage: "PRE",
  opponentScore: 0,
  myScore: 0,
  layout: "horiz",
  hitPointCircles: [],
}

const renderHitCircles = (hitPointCircles: string[]) => {
  return hitPointCircles.map(c => {
    const [x, y, r] = c.split('-')
    return (
      <circle key={c}
        cx={x} cy={y} r={r} strokeWidth={2} fill="none" stroke="#000"
      />
    )
  })
}

const GameView: React.FC = () => {
  const [ play ] = useSound(sfx_src, { sprite: sfxSpriteMap })
  const playRef = useRef(play)
  const [connState, setConnState] = useState<BackendConnectionState | undefined>(undefined)
  const [conn, setConn] = useState<BackendConnection | undefined>(undefined)
  const [state, setState] = useState<InternalState>(INITIAL_STATE)

  const [images, setImages] = useState<[string, string] | undefined>(undefined)

  useEffect(() => {
    playRef.current = play;
    (window as any)['_play'] = play
  }, [play])

  useEffect(() => {
    const player = TEST_PLAYER
    if (window.localStorage['lastPlayerId']) {
      player.id = window.localStorage['lastPlayerId']
    } else {
      window.localStorage['lastPlayerId'] = player.id
    }
    const conn = new BackendConnection(player)
    setConn(conn)
    setConnState(conn.state);
    (window as any)._conn = conn

    conn.on('stateChange', (ev) => {
      console.log("connState change", ev.detail)
      setConnState(ev.detail)
    })

    conn.on('gameEvent', (ev) => {
      console.log("game event", ev.detail)

      setState((s) => {
        const newS = {...s}

        if (ev.detail.name === 'countdown_timer_started') {
          newS.gameStage = "COUNTDOWN"
          newS.gameStartTimestamp = ev.detail.gameStartTimestamp
        } else if (ev.detail.name === "game_finished") {
          newS.gameStage = "FINISHED"
        } else if (ev.detail.name === "opponent_score_update") {
          newS.opponentScore = ev.detail.opponentScore
        }

        return newS
      })

      if (ev.detail.name === 'countdown_timer_started') {
        setTimeout(() => {
          setState(s => ({...s, gameStage: "STARTED"}))
          if (playRef.current) {
            playRef.current({id: "countdown_start"})
          }
        }, ev.detail.gameStartTimestamp - (new Date().getTime()))
      }
    })

    conn.on('serverMessageEvent', async (ev) => {
      if (ev.detail.action === "matchCreatedFromQueue") {
        const m = ev.detail.match
        // todo: DRY
        const images = await Promise.all([
          loadImage(RSRC[m.rsrcId][0]),
          loadImage(RSRC[m.rsrcId][1]),
        ])
        setImages(images.map((i) => i.src) as [string, string])

        const horiz = images[0].height >= images[0].width
        setState((s) => ({
          ...s,
          layout: horiz ? "horiz" : "vert",
          imgHeight: images[0].height,
          imgWidth: images[0].width,
        }))

        await conn.resourcesLoaded()
      }
    })

    return () => {
      conn.disconnect()
      setConn(undefined)
      setConnState(undefined)
    }
  }, [])

  const startNewGame = useCallback(async (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (!conn) return

    const m = await conn.getNewMatch()
    console.log("new match response", m)

    if (m === "enqueued") return

    // todo: DRY
    const images = await Promise.all([
      loadImage(RSRC[m.rsrcId][0]),
      loadImage(RSRC[m.rsrcId][1]),
    ])
    setImages(images.map((i) => i.src) as [string, string])

    const horiz = images[0].height >= images[0].width
    setState((s) => ({
      ...s,
      layout: horiz ? "horiz" : "vert",
      imgHeight: images[0].height,
      imgWidth: images[0].width,
    }))

    await conn.resourcesLoaded()
  }, [conn])

  const reset = useCallback(async (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (!conn) return
    setState(INITIAL_STATE)
    await conn.completeMatch()
  }, [conn])

  const leaveQueue = useCallback(async (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (!conn) return
    await conn.leaveQueue()
    setState(INITIAL_STATE)
  }, [conn])

  const imgClick = useCallback(async (ev: React.MouseEvent<HTMLImageElement>) => {
    if (!conn) return
    if (state.gameStage !== "STARTED") return

    const img    = ev.currentTarget
    const bounds = img.getBoundingClientRect()
    const left   = bounds.left
    const top    = bounds.top
    const x      = ev.pageX - left
    const y      = ev.pageY - top
    const cw     = img.clientWidth
    const ch     = img.clientHeight
    const iw     = img.naturalWidth
    const ih     = img.naturalHeight
    const px     = x / cw * iw
    const py     = y / ch * ih

    console.log(`click at source image pixel (${px}, ${py})`)

    const resp = await conn.sendAction({ tapPos: { x: px, y: py } })
    const myScore = resp.currentScore
    if (typeof myScore !== 'undefined') {
      const c = resp.hitPointCircle!
      setState((s) => ({
        ...s,
        myScore,
        hitPointCircles: [ ...new Set([ ...s.hitPointCircles, `${c.x}-${c.y}-${c.r}` ]) ]
      }))
    }
  }, [conn, state])

  return <div className={s.GameView}>
    { connState?.connection !== "CONNECTED" && <>
      Connecting...
    </>}
    { connState?.player === "IDLE" && connState?.connection === "CONNECTED" && <>
      <button type="button" onClick={startNewGame}>Start new game!</button>
    </>}
    { connState?.player === "IN_QUEUE" && <>
      Currently in queue.. waiting for match..<br />
      <button type="button" onClick={leaveQueue}>Leave queue</button>
    </>}
    { connState?.player === "IN_MATCH" && <>
      { state.gameStage === "COUNTDOWN" && state.gameStartTimestamp && <CountdownTimer to={state.gameStartTimestamp} play={play} />}
      { state.gameStage === "STARTED" && images && <>
        <div>
          Your score: {state.myScore} | Opponent score: {state.opponentScore}
        </div>

        <div className={classNames(s.wrapper, { [s.wrapper__vert]: state.layout === "vert" })}>
          <div className={s.AgameImageView}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${state.imgWidth} ${state.imgHeight}`}>
              {renderHitCircles(state.hitPointCircles)}
            </svg>
            <img src={images[0]} alt="" onClick={imgClick}  draggable={false}/>
          </div>
          <div className={s.AgameImageView}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${state.imgWidth} ${state.imgHeight}`}>
              {renderHitCircles(state.hitPointCircles)}
            </svg>
            <img src={images[1]} alt="" onClick={imgClick} draggable={false} />
          </div>
        </div>
      </> }
      { state.gameStage === "FINISHED" && <>
        Game finished! {connState.currentMatch?.status === "COMPLETE" && connState.currentMatch.result}
        <button type="button" onClick={reset}>Reset</button>
      </> }
    </> }
  </div>
}

export default GameView
