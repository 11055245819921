import { ulid } from "ulid"

const WEBSOCKET_URI = process.env.REACT_APP_WEBSOCKET_URI

export interface Player {
  id: string

  ratings: {
    a: number
  }
}

export const TEST_PLAYER: Player = {
  id: ulid(), ratings: { a: 1500 }
}

// TODO: Matchmaking queue

/**
 * Corresponds with gameRuleSet A
 */
type RuleSetA = {
  maxDifferences: number
}

interface MatchBase {
  matchId: string
  ratingArea: string
  gameRuleSet: string
  rsrcId: string
  rsrcRules: RuleSetA

  playerState: string
}

interface MatchInProgress extends MatchBase {
  status: "IN_PROGRESS"
}

interface MatchComplete extends MatchBase {
  status: "COMPLETE"
  result: "WIN" | "LOSE"
}

export type Match = MatchInProgress | MatchComplete

export interface PlayerAction {
  tapPos: {x: number, y: number}
}

export type HitPointCircle = { x: number, y: number, r: number }

export interface PlayerActionResponse {
  tapResp: "HIT" | "MISS"
  tapHitId?: number
  currentScore?: number
  hitPointCircle?: HitPointCircle
}

export interface BackendConnectionState {
  connection: "IDLE" | "CONNECTED" | "CONNECTING"
  player: "IDLE" | "IN_QUEUE" | "IN_MATCH"

  currentMatch?: Match

  /** ms timestamp when the user entered the queue */
  inQueueSince?: number
}

// countdown timer started -> game started -> opponent score updates -> game finished
type GameEventDetail = {
  name: "countdown_timer_started"
  /** When the countdown timer starts, the game start timestamp is returned with the actual time the game starts */
  gameStartTimestamp: number
} | {
  name: "game_started"
} | {
  name: "opponent_score_update"
  /** When an opponent score updates, it comes in the event */
  opponentScore: number
} | {
  name: "game_finished"
  /** The player ID of the winner */
  gameWinner: string
}

type ServerMessageGetNewMatchResponse = {
  action: "getNewMatch"
  id: string
  match: MatchInProgress | "enqueued"
}

type ServerMessage = {
  action: "ping"
  id: string
} |
ServerMessageGetNewMatchResponse |
{
  action: "gameEvent"
  id: string
  detail: GameEventDetail
} |
{
  action: "matchCreatedFromQueue"
  id: string
  match: MatchInProgress
}

type EventHandler<T = any> = (evt: T) => void

export class BackendConnectionStateChanged extends CustomEvent<BackendConnectionState> {}
export class GameEvent extends CustomEvent<GameEventDetail> {
  constructor(detail: GameEventDetail) {
    super('gameEvent', { detail })
  }
}
export class ServerMessageEvent extends CustomEvent<ServerMessage> {
  constructor(detail: ServerMessage) {
    super('serverMessageEvent', { detail })
  }
}

export class BackendConnection extends EventTarget {
  socket: WebSocket
  pingTimeout?: number
  lastPingId?: string

  messageCallbacks: { [key: string]: (msg: ServerMessage) => void }

  player: Player
  state: BackendConnectionState

  constructor(player: Player) {
    super()

    this.player = player
    this.socket = this._connectWs()
    this.messageCallbacks = {}

    this.on('serverMessageEvent', (evt) => {
      const msg = evt.detail
      if (msg.action === "ping") {
        if (this.lastPingId === msg.id) {
          this.lastPingId = undefined
        }
      }
    })

    this.state = {
      connection: "CONNECTING",
      player: "IDLE",
    }
  }

  private _connectWs() {
    const uri = new URL(WEBSOCKET_URI!)
    uri.searchParams.append('auth', this.player.id)
    this.socket = new WebSocket(uri.toString())

    this.socket.onopen = (ev) => {
      console.log("Socket opened", ev)
      this._setState((s) => ({...s, connection: "CONNECTED"}))
      this._startWsPing()
    }

    this.socket.onerror = (ev) => {
      console.log("Error", ev)
      this._setState((s) => ({...s, connection: "IDLE"}))
      if (this.pingTimeout) {
        window.clearTimeout(this.pingTimeout)
        this.pingTimeout = undefined
      }
      setTimeout(() => {
        this._setState((s) => ({...s, connection: "CONNECTING"}))
        this._connectWs()
      }, 10_000) // TODO: exponential backoff
    }

    this.socket.onmessage = (msg) => {
      const smsg = JSON.parse(msg.data) as ServerMessage

      if (smsg.action === "matchCreatedFromQueue") {
        this._setState((s) => ({ ...s, player: "IN_MATCH", currentMatch: smsg.match }))
      }

      if (smsg.action === "gameEvent") {
        const detail = smsg.detail
        if (detail.name === "game_finished") {
          this._setState((s) => {
            const newS = {...s}
            if (s.currentMatch) {
              newS.currentMatch = {
                ...s.currentMatch,
                status: "COMPLETE",
                result: (detail.gameWinner === this.player.id ? "WIN" : "LOSE")
              }
            }
            return newS
          })
        }
      }

      this.dispatchEvent(new ServerMessageEvent(smsg))

      if (smsg.action === "gameEvent" && !('error' in smsg)) {
        this.dispatchEvent(new GameEvent(smsg.detail))
      }

      const cb = this.messageCallbacks[smsg.id]
      if (cb) {
        cb(smsg)
      }
    }

    return this.socket
  }

  private _startWsPing() {
    // ping is already set, ignore
    if (this.pingTimeout) return
    this.pingTimeout = window.setTimeout(() => {
      if (this.socket.readyState !== this.socket.OPEN) {
        // we're done here
        this.pingTimeout = undefined
        return
      }
      if (this.lastPingId) {
        // ping timeout
        console.log("ping timeout")
      }

      const id = ulid()
      this.lastPingId = id
      this.socket.send(JSON.stringify(
        { "action": "ping", id }
      ))

      this.pingTimeout = undefined
      this._startWsPing()
    }, 30_000)
  }

  private _sendMessageWithExpectedSingleReply<T = any>(msg: any, timeout?: number): Promise<T>
  private _sendMessageWithExpectedSingleReply(msg: any, timeout: number = 3_000) {
    return new Promise((resolve, reject) => {
      const id = ulid()
      this.messageCallbacks[id] = (resp) => {
        delete this.messageCallbacks[id]
        resolve(resp)
      }

      this.socket.send(JSON.stringify({
        ...msg,
        id
      }))

      setTimeout(() => {
        if (typeof this.messageCallbacks[id] === 'undefined') {
          delete this.messageCallbacks[id]
          reject('timeout')
        }
      }, timeout)
    })
  }

  on(event: 'stateChange', handler: EventHandler<BackendConnectionStateChanged>): void
  on(event: 'gameEvent', handler: EventHandler<GameEvent>): void
  on(event: 'serverMessageEvent', handler: EventHandler<ServerMessageEvent>): void
  on(event: string, handler: EventHandler) {
    this.addEventListener(event, handler)
  }

  private _setState(newState: BackendConnectionState | ((oldState: BackendConnectionState) => BackendConnectionState)) {
    if (typeof newState === "function") {
      this.state = newState(this.state)
    } else {
      this.state = newState
    }
    this.dispatchEvent(new BackendConnectionStateChanged('stateChange', { detail: this.state }))
  }

  getNewMatch(): Promise<MatchInProgress | "enqueued">
  async getNewMatch() {
    const resp = await this._sendMessageWithExpectedSingleReply<ServerMessageGetNewMatchResponse>({
      action: "getNewMatch",
      gameRuleSet: "A"
    })

    const match = resp.match

    if (match === "enqueued") {
      this._setState((s) => ({ ...s, player: "IN_QUEUE", inQueueSince: (new Date().getTime()) }))
    } else {
      this._setState((s) => ({ ...s, player: "IN_MATCH", inQueueSince: undefined, currentMatch: match }))
    }

    return match
  }

  resourcesLoaded(): Promise<void>
  async resourcesLoaded() {
    this._sendMessageWithExpectedSingleReply({
      action: "resourcesLoaded"
    })
  }

  sendAction(action: PlayerAction): Promise<PlayerActionResponse>
  async sendAction(action: PlayerAction) {
    const currentMatch = this.state.currentMatch
    if (!currentMatch || currentMatch.status !== "IN_PROGRESS") {
      return
    }

    const resp = await this._sendMessageWithExpectedSingleReply<PlayerActionResponse>({
      action: "gameAction",
      tapPos: action.tapPos,
    })

    return resp
  }

  disconnect(): Promise<void>
  async disconnect() {
    this.socket.close()
    if (this.pingTimeout) {
      window.clearTimeout(this.pingTimeout)
      this.pingTimeout = undefined
    }
    this._setState((s) => ({...s, connection: "IDLE"}))
  }

  completeMatch(): Promise<void>
  async completeMatch() {
    if (this.state.player === "IN_MATCH" && this.state.currentMatch?.status === "COMPLETE") {
      this._setState((s) => ({...s, player: "IDLE", currentMatch: undefined}))
    }
  }

  leaveQueue(): Promise<void>
  async leaveQueue() {
    if (this.state.player === "IN_QUEUE" && this.state.connection === "CONNECTED") {
      await this._sendMessageWithExpectedSingleReply({
        action: "leaveQueue"
      })
      this._setState((s) => ({...s, player: "IDLE", inQueueSince: undefined}))
    }
  }
}
