import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom"
import GameView from './GameView'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/game">
          <GameView />
        </Route>
        <Route path="/" exact>
          Hello!<br/>
          <Link to="/game">Start game</Link>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
